import "./NewAccount.css";

import { API, Auth } from "aws-amplify";
import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Row,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import MdArrowForward from "react-ionicons/lib/MdArrowForward";
import axios from "axios";
import IosArrowForward from "react-ionicons/lib/IosArrowForward";

export default function NewAccount(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [showWarning, setShowWarning] = useState(true);

  function validateForm() {
    return password.length >= 6;
  }

  useEffect(() => {
    function loadProfile(userId) {
      return API.get("profiles", `/myprofile/${userId}`);
    }

    async function onLoad() { 
      try {
        const user = await Auth.currentUserInfo();
        const profile = await loadProfile(user["id"]);
        setEmail(user.attributes.email)
        setProfile(profile);
      } catch (e) {
        
      }
    }

    onLoad();
  }, []);

  const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const account = await createAccount({
        userId: "us-east-1:cbbc073d-c081-420c-9ee7-eba81d2c6e6d",
        email,
        password,
        firstName: "none",
        linkedinUrl: "none",
        lastName: "none",
        profileId: profile.profileId,
      });

      verifyAccount(account);
      await pause(30000);
      props.props.history.push(`/accounts/${account.accountId}`)
      setIsLoading(false);
    } catch (e) {
      alert(e);
      setIsLoading(false);
      
    }
    
  }

  async function verifyAccount(account) {
    return axios.post(
      "https://legacy.api.conversify.ai/connectAccount",
      null,
      {
        params: { userId: account.userId, accountId: account.accountId }
      }
    )
  }

  function createAccount(account) {
    return API.post("accounts", "/accounts", {
      body: account,
    });
  }

  return (
    <div className="NewAccount">
      {
        isLoading ? (
          <div>
          <div className="text-center">
            <Row>
              <Col sm={12}>
                <p style={{ opacity: 1, textAlign: "center" }}>
                  <b>Connecting your account ...</b>
                </p>
                <p style={{ opacity: 1, textAlign: "center" }}>
                  Don't touch anything - this won't take too long
                </p>
                <img
                  style={{ width: "100%" }}
                  alt="loading-gif"
                  src="https://conversify-marketing.s3.amazonaws.com/Ryse_GIF.gif"
                />
              </Col>
            </Row>
          </div>
        </div>
        ) : (
          <div>
            {
              showWarning ? (
                <div className='text-left'>
                  <h3>
                    <b>Connect LinkedIn account</b>
                  </h3>
                  <p>
                    Connecting your LinkedIn account allows us to perform
                    actions on your behalf. Your information is safe and secured.
                  </p>
                  <br />
                  <p>
                    <button
                      className="btn-secondary"
                      onClick={() => setShowWarning(false)}
                    >
                      Connect LinkedIn
                    </button>
                  </p>
                  <br/>
                  <h4 style={{cursor: 'pointer'}} onClick={() => props.props.handleLogout()}>
                    <IosArrowForward fontSize="16px" /> Logout
                  </h4>
                </div>
              ) : (
                <Row>
                  <Col sm={12}>
                    <form onSubmit={handleSubmit}>
                      <p className="text-left">
                        <img
                          alt="linkedin logo"
                          height="35"
                          src="https://conversify-marketing.s3.amazonaws.com/linkedin.svg"
                        />
                      </p>
                      <FormGroup controlId="email">
                        <ControlLabel>LinkedIn Email</ControlLabel>
                        <FormControl
                          value={email}
                          type="email"
                        />
                      </FormGroup>
                      <FormGroup controlId="password">
                        <ControlLabel>LinkedIn Password</ControlLabel>
                        <FormControl
                          value={password}
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </FormGroup>
                      <button
                        className="other-btn pull-right"
                        type="submit"
                        disabled={!validateForm() || isLoading}
                      >
                        Connect Account
                        <MdArrowForward fontSize="16px" />
                      </button>
                    </form>
                    <br/>
                    <h4 style={{cursor: 'pointer'}} onClick={() => props.props.handleLogout()}>
                      <IosArrowForward fontSize="16px" /> Logout
                    </h4>
                  </Col>
                </Row>
              )
            }
          </div>
        )
      }
    </div>
  );
}
