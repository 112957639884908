import "./EditCampaign.css";

import { ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import LoaderButton from "../components/LoaderButton";
import MdTrash from "react-ionicons/lib/MdTrash";

export default function EditCampaign(props) {
  const [title, setTitle] = useState("");
  const [dailyLimit, setDailyLimit] = useState("");
  const [contacts, setContacts] = useState(0);
  const [paused, setPaused] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    function loadCampaign() {
      return API.post("campaigns", "/viewcampaign", {
        body: {
          userId: "us-east-1:cbbc073d-c081-420c-9ee7-eba81d2c6e6d",
          campaignId: props.campaignId,
        },
      });
    }


    async function onLoad() {
      try {
        const campaign = await loadCampaign();
        setTitle(campaign.title);
        setPaused(campaign.paused);
        setDailyLimit(parseInt(campaign.dailyLimit));
        setContacts(campaign.contacts);
        setIsLoading(false);
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.campaignId]);

  function deleteCampaign() {
    return API.del("campaigns", `/campaigns/${props.campaignId}`);
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this campaign?"
    );

    if (!confirmed) {
      return;
    }

    setIsLoading(true);
    try {
      await deleteCampaign();
      props.props.props.props.history.push(`/dashboard`);
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  function validateForm() {
    return title.length > 0;
  }

  function saveCampaign(campaign) {
    return API.put("campaigns", `/campaigns/${props.campaignId}`, {
      body: campaign,
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await saveCampaign({ 
        userId: "us-east-1:cbbc073d-c081-420c-9ee7-eba81d2c6e6d",
        title,
        paused, 
        dailyLimit,
        contacts,
      });
      setIsLoading(false);
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }
  return (
    <div className="EditCampaign">
      {
        props.props.isAdmin && <p className="text-center">
        <span onClick={handleDelete} className="other-btn">
          <MdTrash fontSize="14px" />
          Delete Campaign
        </span>
      </p>
      }
      
      {!isLoading ? (
        <form onSubmit={handleSubmit}>
          <FormGroup controlId="title">
            <ControlLabel>Title</ControlLabel>
            <FormControl
              value={title}
              type="text"
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormGroup>
          <FormGroup controlId="dailyLimit">
            <ControlLabel>Daily Sending Limit</ControlLabel>
            <FormControl
              value={dailyLimit}
              type="number"
              onChange={(e) => setDailyLimit(parseInt(e.target.value))}
            />
          </FormGroup>

          <p className="text-right">
            <LoaderButton
              type="submit"
              className="other-btn"
              isLoading={isLoading}
              disabled={!validateForm()}
            >
              Update Campaign
            </LoaderButton>
          </p>
        </form>
      ) : (
        <IosRefresh fontSize="60px" color="#FF10A2" rotate={true} />
      )}
    </div>
  );
}
