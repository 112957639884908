import "./Contacts.css";

import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import { CSVLink } from "react-csv";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import LoaderButton from "../components/LoaderButton";
import MdContacts from "react-ionicons/lib/MdContacts";
import axios from "axios";

export default function Contacts(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [account, setAccount] = useState(null);
  const [navLink, setNavLink] = useState("");
  const [csvName, setCsvName] = useState("");
  const [csvData, setCsvData] = useState(null);
  const [viewNewContactsModel, setViewNewContactsModel] = useState(null);

  useEffect(() => {
    async function onLoad() {
      try {
        const accounts = await loadAccounts();
        const account = accounts.filter(
          (account) => account.accountId === props.accountId
        )[0];
        setAccount(account);
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated, props.accountId]);

  function loadAccounts() {
    return API.post("accounts", "/adminaccounts", {
      body: {
        userId: "us-east-1:cbbc073d-c081-420c-9ee7-eba81d2c6e6d",
      },
    });
  }

  function validateForm() {
    return navLink.includes("linkedin.com/sales/lists/people/");
  }

  async function generateCSV() {
    setIsLoading(true);
    try {
      // test https://www.linkedin.com/sales/lists/people/6664958058680188928?sortCriteria=CREATED_TIME
      const { data } = await axios.post(
        "https://legacy.api.conversify.ai/exportSalesNavList",
        null,
        {
          params: {
            accountId: account.accountId,
            userId: account.userId,
            listUrl: navLink,
          },
        }
      );

      const { name, csv } = data;
      setCsvName(name);
      setCsvData(csv);
      setIsLoading(false);
    } catch (e) {
      setIsError(true);
      setIsLoading(false);
      console.log(e);
    }
  }

  function renderContactsDownload() {
    return (
      <div className="Contacts">
        {isError ? (
          <div>
            <Row>
              <Col sm={12} className="text-center">
                <p style={{ opacity: 1, textAlign: "center" }}>
                  <b>
                    {" "}
                    <span role="img" aria-label="sad">
                      😞
                    </span>{" "}
                    Hmm ...{" "}
                  </b>
                </p>
                <p>There was an issue creating your list of contacts.</p>
              </Col>
            </Row>
          </div>
        ) : !isLoading ? (
          csvData ? (
            <Row>
              <Col sm={12}>
                <CSVLink
                  data={csvData}
                  filename={`${csvName}.csv`}
                  target="_blank"
                  className="other-btn"
                >
                  Download your CSV
                </CSVLink>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm={12}>
                <FormGroup controlId="dailyLimit">
                  <ControlLabel>
                    Sales Nav URL{" "}
                    <small>
                      (this must be a list from {account.firstName}{" "}
                      {account.lastName}'s Sales Nav)
                    </small>
                  </ControlLabel>
                  <FormControl
                    value={navLink}
                    type="text"
                    placeholder="https://www.linkedin.com/sales/lists/people/6664942858790850560?sortCriteria=CREATED_TIME"
                    onChange={(e) => setNavLink(e.target.value)}
                  />
                </FormGroup>
                <LoaderButton
                  type="submit"
                  className="other-btn pull-right"
                  isLoading={isLoading}
                  disabled={!validateForm()}
                  onClick={() => generateCSV()}
                >
                  Download
                </LoaderButton>
              </Col>
            </Row>
          )
        ) : (
          <div>
            <br />
            <br />
            <div className="form-wrapper text-center">
              <Row>
                <Col sm={12}>
                  <p style={{ opacity: 1, textAlign: "center" }}>
                    <b>Exporting your contacts ...</b>
                  </p>
                  <p style={{ opacity: 1, textAlign: "center" }}>
                    Don't touch anything - this will take about a minute.
                  </p>
                  <img
                    style={{ width: "100%" }}
                    alt="loading-gif"
                    src="https://conversify-marketing.s3.amazonaws.com/Ryse_GIF.gif"
                  />
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    );
  }

  function renderContacts() {
    return (
      <div className="text-center">
        <br />
        <br />
        <br />
        <br />
        <p className="text-center">
          <MdContacts fontSize="64px" color="#FF10A2" />
        </p>
        <p>Convert a LinkedIn Sales Nav list into a CSV of contacts</p>
        <p className="text-center">
          <button
            onClick={() => setViewNewContactsModel(true)}
            className="btn-secondary"
          >
            Get Contacts
          </button>
        </p>
        <br />
      </div>
    );
  }

  return (
    <div className="">
      {isLoading ? (
        <div className="container text-center">
          <br />
          <br />
          <br />
          <br />
          <IosRefresh fontSize="60px" color="#FF10A2" rotate={true} />
        </div>
      ) : (
        renderContacts()
      )}
      <Modal show={viewNewContactsModel} dialogClassName="modal-90w">
        <Modal.Body>
          <div className="modal-card text-center">
            <button
              onClick={() => setViewNewContactsModel(false)}
              className="other-btn"
            >
              close
            </button>
            {renderContactsDownload()}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
