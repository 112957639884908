import "./EditAccount.css";
import {
  ControlLabel,
  FormControl,
  FormGroup,
  Row, 
  Col
} from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import axios from "axios";


export default function EditAccount(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function loadAccounts() {
    return API.post("accounts", "/adminaccounts", {
      body: {
        userId: "us-east-1:cbbc073d-c081-420c-9ee7-eba81d2c6e6d",
      },
    });
  }


  useEffect(() => {

    async function onLoad() {
      try {
        const accounts = await loadAccounts();
        const account = accounts.filter(account => account.accountId === props.match.params.id)[0]

        console.log(account)

        setEmail(account.email);
        setPassword("");
        setFirstName(account.firstName);
        setLastName(account.lastName);
        setLinkedinUrl(account.linkedinUrl);
        setIsLoading(false);
      } catch (e) {
        alert(e);
        
      }
    }

    onLoad();
  }, [props.match.params.id]);

  function saveAccount(account) {
    return API.put("accounts", `/accounts/${props.match.params.id}`, {
      body: account,
    });
  }

  const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await saveAccount({
        userId: "us-east-1:cbbc073d-c081-420c-9ee7-eba81d2c6e6d",
        email,
        password,
        firstName,
        lastName,
        linkedinUrl,
      });
      const accounts = await loadAccounts();
      const account = accounts.filter(account => account.accountId === props.match.params.id)[0]

      console.log(account)
      verifyAccount(account)
      await pause(30000);
      props.history.push(`/accounts/${props.match.params.id}`)
    } catch (e) {
      
      setIsLoading(false);
    }
  }


async function verifyAccount(account) {
  return axios.post(
    "https://legacy.api.conversify.ai/connectAccount",
    null,
    {
      params: { userId: account.userId, accountId: account.accountId }
    }
  ).catch(error => {
    console.log(error)
    props.history.push(`/accounts/${account.accountId}`);
  })
}

  return (
    <div className="EditAccount">
      <br />
      <br />
      {
        isLoading ? (
        <div className="form-wrapper">
          <div className="text-center">
            <Row>
              <Col sm={12}>
                <p style={{ opacity: 1, textAlign: "center" }}>
                  <b>Connecting your account ...</b>
                </p>
                <p style={{ opacity: 1, textAlign: "center" }}>
                  Don't touch anything - this won't take too long
                </p>
                <img
                  style={{ width: "100%" }}
                  alt="loading-gif"
                  src="https://conversify-marketing.s3.amazonaws.com/Ryse_GIF.gif"
                />
              </Col>
            </Row>
          </div>
        </div>
        ) : (
          <div className="form-wrapper">
          <a className="other-btn" href={`/accounts/${props.match.params.id}`}>
            back
          </a>
          <br />
          <br />
          <form onSubmit={handleSubmit}>
              <FormGroup className="hidden" controlId="linkedinUrl">
                <ControlLabel>LinkedIn Url</ControlLabel>
                <FormControl
                  value={linkedinUrl}
                  type="text"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </FormGroup>
              <br />
              <FormGroup className="hidden" controlId="email">
                <ControlLabel>Email</ControlLabel>
                <FormControl
                  value={email}
                  type="text"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
              <FormGroup controlId="password">
                <ControlLabel>LinkedIn Password</ControlLabel>
                <FormControl
                  value={password}
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="hidden" controlId="firstName">
                <ControlLabel>First Name</ControlLabel>
                <FormControl
                  value={firstName}
                  type="text"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="hidden" controlId="lastName">
                <ControlLabel>Last Name</ControlLabel>
                <FormControl
                  value={lastName}
                  type="text"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </FormGroup>
              <LoaderButton
                type="submit"
                className="other-btn"
                isLoading={isLoading}
              >
                Update password
              </LoaderButton>
            </form>
        </div>
        )
      }

    </div>
  );
}
