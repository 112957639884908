import "./Settings.css";

import { API, Auth } from "aws-amplify";
import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Row,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";

import IosRefresh from "react-ionicons/lib/IosRefresh";
import NewAccount from "./NewAccount";
import Payment from "./Payment";

export default function Settings(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [user, setUser] = useState(null);
  const [accounts, setAccounts] = useState(1);
  const [hasPaid, setHasPaid] = useState(false);

  useEffect(() => {
    function loadProfile(userId) {
      return API.get("profiles", `/myprofile/${userId}`);
    }

    async function onLoad() {
      try {
        const user = await Auth.currentUserInfo();
        const profile = await loadProfile(user["id"]);
        setProfile(profile);
        setIsLoading(true);
        setUser(user);
      } catch (e) {
        console.log(e);
        console.log("cant find user");
      }
    }

    onLoad();
  }, []);

  function handleFieldChange(evt) {
    setAccounts(parseInt(evt.target.value));
  }

  function makePayment(payment) {
    return API.post("profiles", "/addaccounts", {
      body: payment,
    });
  }

  async function handlePayment(event) {
    event.preventDefault();

    setIsLoading(false);

    const subscriptionId = profile.subscriptionId;
    const profileId = profile.profileId;
    let accountsToPurchase = accounts;
    if (profile.accountsPurchased)
      accountsToPurchase = profile.accountsPurchased + accounts;

    const payment = {
      subscriptionId,
      accounts: accountsToPurchase,
      profileId,
    };

    try {
      await makePayment(payment);
      setHasPaid(true);
      setIsLoading(true);
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  function handlePaymentValidation() {
    return accounts >= 1;
  }

  return (
    <div className="Settings">
      {!isLoading ? (
        <IosRefresh fontSize="60px" color="#FF10A2" rotate={true} />
      ) : profile.paid ? (
        hasPaid ? (
          <NewAccount
            setScreen={props.setScreen}
            history={props.props.props.history}
          />
        ) : (
          <Row>
            <Col sm={12}>
              <div className="text-left">
                <FormGroup bsSize="large" controlId="accounts">
                  <ControlLabel>
                    How many accounts would you like to add?
                  </ControlLabel>
                  <FormControl
                    type="number"
                    value={accounts}
                    onChange={handleFieldChange}
                  />
                </FormGroup>
                <p className="text-left">
                  <small>
                    You will pay $
                    {(profile.accountsPurchased
                      ? profile.accountsPurchased + accounts
                      : accounts) * 30}{" "}
                    USD per month after adding {accounts}{" "}
                    {accounts === 1 ? "account" : "accounts"}
                  </small>
                </p>
                <br />
                <button
                  className="btn-secondary"
                  onClick={handlePayment}
                  disabled={!handlePaymentValidation()}
                >
                  Purchase {accounts === 1 ? "account" : "accounts"}
                </button>
              </div>
            </Col>
          </Row>
        )
      ) : (
        <div className="text-center">
          <Payment props={props} user={user} profileId={profile.profileId} />
        </div>
      )}
    </div>
  );
}
