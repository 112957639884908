import "./Broadcast.css";
import "react-dropdown/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.min.css";

import { Col, ControlLabel, FormControl, Modal, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import Dropdown from "react-dropdown";
import Loading from "./Loading";
import MdCheckmark from "react-ionicons/lib/MdCheckmark";
import MdMegaphone from "react-ionicons/lib/MdMegaphone";
import axios from "axios";

export default function Contacts(props) {
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [campaigns, setCampaigns] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedContacts, setSelectedContacts] = useState(null);
  const [messageText, setMessageText] = useState("");
  const [messageLoad, setMessageLoad] = useState(false);
  const [coolContacts, setCoolContacts] = useState([]);
  const [viewBroadcastModel, setViewBroadcastModel] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  useEffect(() => {
    async function onLoad() {
      const userCampaigns = props.campaigns.filter(
        (campaign) =>
          campaign.accountId === props.accountId && campaign.conversations > 0
      );

      let contacts = await loadContacts(userCampaigns[0].campaignId);

      try {
        contacts = contacts.filter(
          (contact) =>
            "conversationMessages" in contact &&
            contact.conversationMessages.length > 2
        );
        const campaigns = [];
        for (let campaignIndex in userCampaigns) {
          const value = userCampaigns[campaignIndex].campaignId;
          const label = userCampaigns[campaignIndex].title;
          campaigns.push({
            label,
            value,
          });
        }
        if (campaigns.length > 0) {
          setCampaigns(campaigns);
          setSelectedCampaign(campaigns[0]);
          setContacts(contacts);
          setSelectedContacts(
            contacts.filter(
              (contact) =>
                contact.isConnection === true &&
                contact.campaignId === campaigns[0].value
            )
          );
        }
      } catch (e) {
        console.log(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated, props.accountId, props.campaigns]);

  function loadContacts(campaignId) {
    return API.get("contacts", `/campaigns/${campaignId}/contacts`);
  }

  async function sendMessage(currentContact) {
    try {
      await axios.post(
        "https://legacy.api.conversify.ai/enqueue",
        {
          campaignId: currentContact.campaignId,
          accountKey: {
            accountId: currentContact.accountId,
            userId: currentContact.userId,
          },
          commands: [
            {
              action: "sendMessage",
              contactId: currentContact.contactId,
              params: { content: messageText },
            },
          ],
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "https://www.conversify.ai",
            "Content-Type": "application/json",
          },
        }
      );
    } catch (e) {
      console.log(e);
    }
  }

  function addContact(contactId) {
    if (coolContacts.includes(contactId)) {
      coolContacts.splice(coolContacts.indexOf(contactId), 1);
      setCoolContacts(coolContacts);
      setSelectedContacts(
        selectedContacts.filter(
          (contact) => !coolContacts.includes(contact.contactId)
        )
      );
    } else {
      coolContacts.push(contactId);
      setCoolContacts(coolContacts);
      setSelectedContacts(
        selectedContacts.filter(
          (contact) => !coolContacts.includes(contact.contactId)
        )
      );
    }
  }

  function removeContact(contactId) {
    coolContacts.splice(coolContacts.indexOf(contactId), 1);
    setCoolContacts(coolContacts);
    setSelectedContacts(
      selectedContacts.filter(
        (contact) => !coolContacts.includes(contact.contactId)
      )
    );
  }

  async function updateCampaign(campaign) {
    setSelectedCampaign(campaign);
    setCoolContacts([]);
    let contacts = await loadContacts(campaign.value);
    setContacts(contacts);
    setSelectedContacts(
      contacts.filter(
        (contact) =>
          "conversationMessages" in contact &&
          contact.conversationMessages.length > 2
      )
    );
  }

  function broadcastMessage() {
    setMessageLoad(true);
    for (let idx in coolContacts) {
      const contact = contacts.filter(
        (contact) => contact.contactId === coolContacts[idx]
      )[0];
      sendMessage(contact);
    }
    setCoolContacts([]);
    setMessageLoad(false);
    setMessageSent(true);
  }

  function finishBroadcast() {
    setViewBroadcastModel(false);
    setMessageSent(false);
    setMessageText("");
  }

  return (
    <div className="Broadcast">
      {isLoading ? (
        <Loading />
      ) : contacts.length === 0 ? (
        <div>
          <Row>
            <Col sm={12} className="text-center">
              <br />
              <br />
              <br />
              <br />
              <p className="text-center">
                <MdMegaphone fontSize="64px" color="#FF10A2" />
              </p>
              <p>Broadcast a message to all your connections at once.</p>
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <Row>
            <Col sm={3}>
              <Dropdown
                value={selectedCampaign}
                options={campaigns}
                onChange={updateCampaign}
                placeholder="Select a campaign"
              />
              <div className="contacts-container">
                {selectedContacts.map((contact, i) =>
                  contact.fullName !== "none" ? (
                    <h4
                      key={i}
                      onClick={() => addContact(contact.contactId)}
                      className={
                        contact && "conversationSentiment" in contact
                          ? contact.conversationSentiment
                          : null
                      }
                    >
                      <b>
                        {contact.firstName}{" "}
                        {contact.lastName !== "none" ? contact.lastName : null}{" "}
                      </b>
                      <br />
                      {contact.linkedinHeadline}
                    </h4>
                  ) : null
                )}
              </div>
            </Col>
            <Col sm={9}>
              {coolContacts.length > 0 ? (
                <div>
                  <br />
                  <p className="text-left">
                    <button
                      className="other-btn"
                      onClick={() => setViewBroadcastModel(true)}
                    >
                      Broadcast Message
                    </button>
                  </p>
                  {coolContacts.map((id) => {
                    const contact = contacts.filter(
                      (contact) => contact.contactId === id
                    )[0];
                    return (
                      <h4
                        key={id}
                        onClick={() => removeContact(contact.contactId)}
                        className={
                          contact && "conversationSentiment" in contact
                            ? contact.conversationSentiment
                            : null
                        }
                      >
                        <b>
                          {contact && contact.firstName}{" "}
                          {contact && contact.lastName !== "none"
                            ? contact.lastName
                            : null}{" "}
                        </b>
                        <br />
                        {contact && contact.linkedinHeadline}
                      </h4>
                    );
                  })}
                </div>
              ) : (
                <div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <div className="text-center">
                    <Row>
                      <Col sm={12}>
                        <p style={{ opacity: 1, textAlign: "center" }}>
                          <MdMegaphone fontSize="60px" color={"#FF10A2"} />
                        </p>
                        <p style={{ opacity: 1, textAlign: "center" }}>
                          <b>
                            Broadcast the same message to multiple connections
                            are the same time
                          </b>
                        </p>
                        <p style={{ opacity: 1, textAlign: "center" }}>
                          Start by selecting a list of connections to the right.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      )}
      <Modal show={viewBroadcastModel} dialogClassName="modal-90w">
        <Modal.Body>
          {messageSent ? (
            <div className="text-center">
              <button onClick={() => finishBroadcast()} className="other-btn">
                close
              </button>
              <br className="clearfix" />
              <h1>
                <MdCheckmark fontSize="60px" color="#FF10A2" />
              </h1>
              <p style={{ textAlign: "center" }}>
                <b>Messsages Sent!</b>
              </p>
              <p style={{ textAlign: "center" }}>
                Check your LinkedIn accout to make sure the messages were sent.
              </p>
              <br className="clearfix" />
              <br className="clearfix" />
            </div>
          ) : !messageLoad ? (
            <div className="modal-card text-center">
              <button
                onClick={() => setViewBroadcastModel(false)}
                className="other-btn"
              >
                close
              </button>
              <br className="clearfix" />
              <ControlLabel>Message</ControlLabel>
              <FormControl
                value={messageText}
                componentClass="textarea"
                onChange={(e) => setMessageText(e.target.value)}
              />
              <br />
              <p className="text-left">
                <button
                  onClick={() => broadcastMessage()}
                  className="other-btn"
                >
                  Send Message
                </button>
              </p>
            </div>
          ) : (
            <div>
              <br />
              <br />
              <div className="text-center">
                <Row>
                  <Col sm={12}>
                    <p style={{ opacity: 1, textAlign: "center" }}>
                      <b>Broadcasting your message to selected contacts ...</b>
                    </p>
                    <p style={{ opacity: 1, textAlign: "center" }}>
                      Don't touch anything - this will take about a minute.
                    </p>
                    <img
                      style={{ width: "100%" }}
                      alt="loading-gif"
                      src="https://conversify-marketing.s3.amazonaws.com/Ryse_GIF.gif"
                    />
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
