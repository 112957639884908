import "./Contacts.css";

import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Row,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import { CSVLink } from "react-csv";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import LoaderButton from "../components/LoaderButton";
import axios from "axios";

export default function ExportRegularSearch(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [account, setAccount] = useState(null);
  const [navLink, setNavLink] = useState("");
  const [csvName, setCsvName] = useState("");
  const [csvData, setCsvData] = useState(null);

  useEffect(() => {
    async function onLoad() {
      try {
        const accounts = await loadAccounts();
        const account = accounts.filter(
          (account) => account.accountId === props.accountId
        )[0];
        setAccount(account);
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated, props.accountId]);

  function loadAccounts() {
    return API.post("accounts", "/adminaccounts", {
      body: {
        userId: "us-east-1:cbbc073d-c081-420c-9ee7-eba81d2c6e6d",
      },
    });
  }

  function validateForm() {
    return navLink.includes("linkedin.com/search/results/people/?keywords=");
  }

  async function generateCSV() {
    setIsDownloadLoading(true);
    try {
      const { data } = await axios.post(
        "https://legacy.api.conversify.ai/exportSearch",
        null,
        {
          params: {
            accountId: account.accountId,
            userId: account.userId,
            searchUrl: navLink,
          },
        }
      );

      const { name, csv } = data;
      setCsvName(name);
      setCsvData(csv);
      setIsDownloadLoading(false);
    } catch (e) {
      setIsError(true);
      setIsDownloadLoading(false);
    }
  }

  function renderContactsDownload() {
    return (
      <div className="Contacts">
        {isError ? (
          <div>
            <Row>
              <Col sm={12} className="text-center">
                <p style={{ opacity: 1, textAlign: "center" }}>
                  <b>
                    {" "}
                    <span role="img" aria-label="sad">
                      😞
                    </span>{" "}
                    Hmm ...{" "}
                  </b>
                </p>
                <p>
                  There was an issue creating your list of contacts. Make sure
                  this list is from {account.firstName} {account.lastName}'s
                  Sales Nav account.
                </p>
              </Col>
            </Row>
            <Row>
              <Col smOffset={4} sm={4}>
                <div className="s">
                  <a
                    style={{
                      width: "100%",
                      display: "block",
                      marginTop: "0pxs",
                    }}
                    className="other-btn"
                    href="https://calendly.com/adam-scanlon/conversify-meeting"
                  >
                    Schedule a call
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        ) : !isDownloadLoading ? (
          csvData ? (
            <Row>
              <Col sm={12}>
                <p style={{ textAlign: "left" }}>
                  <span className="blue">
                    Your list has been successfully exported, download it below.
                  </span>
                </p>
                <CSVLink
                  data={csvData}
                  filename={`${csvName}.csv`}
                  target="_blank"
                  style={{ float: "left" }}
                  className="other-btn"
                >
                  Download your CSV
                </CSVLink>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm={12}>
                <FormGroup controlId="dailyLimit">
                  <ControlLabel>
                    {account.firstName}'s LinkedIn search URL{" "}
                  </ControlLabel>
                  <FormControl
                    value={navLink}
                    type="text"
                    placeholder="https://www.linkedin.com/search/results/people/?keywords=teachers%20in%20toronto"
                    onChange={(e) => setNavLink(e.target.value)}
                  />
                </FormGroup>
                <LoaderButton
                  type="submit"
                  className="other-btn pull-right"
                  isLoading={isLoading}
                  disabled={!validateForm()}
                  onClick={() => generateCSV()}
                >
                  Download
                </LoaderButton>
                <p style={{ textAlign: "left" }}>
                  <span className="blue">
                    This list must be from {account.firstName}{" "}
                    {account.lastName}'s LinkedIn account.
                  </span>
                </p>
              </Col>
            </Row>
          )
        ) : (
          <Row>
            <Col sm={12}>
              <p style={{ opacity: 1, textAlign: "center" }}>
                <b>Exporting your contacts ...</b>
              </p>
              <p style={{ opacity: 1, textAlign: "center" }}>
                Don't touch anything - this will take about a minute.
              </p>
              <img
                style={{ width: "100%" }}
                alt="loading-gif"
                src="https://conversify-marketing.s3.amazonaws.com/Ryse_GIF.gif"
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }

  return (
    <div className="">
      {isLoading ? (
        <div className="text-center">
          <br />
          <IosRefresh fontSize="60px" color="#FF10A2" rotate={true} />
        </div>
      ) : (
        renderContactsDownload()
      )}
    </div>
  );
}
