import { Route, Switch } from "react-router-dom";

import About from "./containers/About";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import Campaigns from "./containers/Campaigns";
import Contacts from "./containers/Contacts";
import ContactsLoader from "./containers/ContactsLoader";
import Conversations from "./containers/Conversations";
import ConversifyConversations from "./containers/ConversifyConversations";
import Dashboard from "./containers/Dashboard";
import EditAccount from "./containers/EditAccount";
import EditCampaign from "./containers/EditCampaign";
import EditContact from "./containers/EditContact";
import EditTemplate from "./containers/EditTemplate";
import GodView from "./containers/GodView";
import Home from "./containers/Home";
import Login from "./containers/Login";
import NewCampaign from "./containers/NewCampaign";
import NewContacts from "./containers/NewContacts";
import NewTemplate from "./containers/NewTemplate";
import NotFound from "./containers/NotFound";
import Onboarding from "./containers/Onboarding";
import Pricing from "./containers/Pricing";
import Privacy from "./containers/Privacy";
import React from "react";
import ResetPassword from "./containers/ResetPassword";
import Settings from "./containers/Settings";
import Signup from "./containers/Signup";
import Templates from "./containers/Templates";
import Terms from "./containers/Terms";
import Thanks from "./containers/Thanks";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import ViewAccount from "./containers/ViewAccount";
import ViewCampaign from "./containers/ViewCampaign";
import ViewContact from "./containers/ViewContact";
import ViewTemplate from "./containers/ViewTemplate";

export default function Routes({ appProps }) {
  return (
    <Switch>
      <AppliedRoute path="/" exact component={Home} appProps={appProps} />
      <UnauthenticatedRoute
        path="/login"
        exact
        component={Login}
        appProps={appProps}
      />
      <UnauthenticatedRoute
        path="/r/:id"
        exact
        component={Signup}
        appProps={appProps}
      />
      <UnauthenticatedRoute
        path="/login/reset"
        exact
        component={ResetPassword}
        appProps={appProps}
      />
      <AppliedRoute path="/terms" exact component={Terms} appProps={appProps} />
      <AppliedRoute
        path="/privacy"
        exact
        component={Privacy}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/accounts"
        exact
        component={Home}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/accounts/new"
        exact
        component={Onboarding}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/accounts/:id"
        exact
        component={ViewAccount}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/accounts/:accountId/campaigns/:id"
        exact
        component={ViewCampaign}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/accounts/:id/edit"
        exact
        component={EditAccount}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/dashboard"
        exact
        component={Dashboard}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/accounts/:id/new"
        exact
        component={NewCampaign}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/conversifycampaigns"
        exact
        component={GodView}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/conversifyconversations"
        exact
        component={ConversifyConversations}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/campaigns"
        exact
        component={Campaigns}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/campaigns/:id"
        exact
        component={ViewCampaign}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/campaigns/:id/edit"
        exact
        component={EditCampaign}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/campaigns/:id/contacts/new"
        exact
        component={NewContacts}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/contacts"
        exact
        component={Contacts}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/contacts/:id"
        exact
        component={ViewContact}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/contacts/:id/edit"
        exact
        component={EditContact}
        appProps={appProps}
      />

      <AuthenticatedRoute
        path="/templates/new"
        exact
        component={NewTemplate}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/templates/:id"
        exact
        component={ViewTemplate}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/templates/:id/edit"
        exact
        component={EditTemplate}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/templates"
        exact
        component={Templates}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/conversations"
        exact
        component={Conversations}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/loading"
        exact
        component={ContactsLoader}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/upgrade"
        exact
        component={Settings}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/thanks"
        exact
        component={Thanks}
        appProps={appProps}
      />
      <AppliedRoute path="/about" exact component={About} appProps={appProps} />
      <AppliedRoute
        path="/pricing"
        exact
        component={Pricing}
        appProps={appProps}
      />
      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
}
