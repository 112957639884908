import "./Accounts.css";

import { Col, Modal, Row, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import Broadcast from "./Broadcast";
import Campaigns from "./Campaigns";
import Contacts from "./Contacts";
import Conversations from "./Conversations";
import IosApps from "react-ionicons/lib/IosApps";
import IosArrowDown from "react-ionicons/lib/IosArrowDown";
import IosArrowForward from "react-ionicons/lib/IosArrowForward";
import IosPaper from "react-ionicons/lib/IosPaper";
import IosChatboxes from "react-ionicons/lib/IosChatboxes";
import IosFunnel from "react-ionicons/lib/IosFunnel";
import IosHome from "react-ionicons/lib/IosHome";
import IosSettings from "react-ionicons/lib/IosSettings";
import NewAccount from "./NewAccount";
import Settings from "./Settings";
import Templates from "./Templates";
import ViewCampaign from "./ViewCampaign";

export default function Accounts(props) {
  const [accounts, setAccounts] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [account, setAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showAccountList, setAccountLimit] = useState(false);
  const [screen, setScreen] = useState("home");
  const [viewAddAaccountModal, setViewAddAccountModal] = useState(false);
  const [viewPaymentModal, setViewPaymentModal] = useState(false);
  const [viewCrmModal, setViewCrmModal] = useState(false);
  const [viewReferFriend, setViewReferFriend] = useState(false);
  const [viewCampaignModal, setViewCampaignModal] = useState(false);
  const [campaignId, setCampaignId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    async function onLoad() {
      try {
        const accounts = await loadAccounts();
        const campaigns = await loadCampaigns();
        setIsAdmin(props.props.isAdmin);
        if (!props.props.isAdmin) setScreen("templates");

        const account = accounts.filter(
          (account) => account.accountId === props.accountId
        )[0];
        setCampaigns(campaigns);
        setAccount(account);
        setAccounts(accounts);
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.accountId, props.props.isAdmin]);

  function deleteAccount() {
    return API.del("accounts", `/accounts/${account.accountId}`);
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this account?"
    );

    if (!confirmed) {
      return;
    }

    setIsLoading(true);

    try {
      await deleteAccount();
      props.props.history.push(`/`);
    } catch (e) {
      alert(e);
      
      setIsLoading(false);
    }
  }


  function loadCampaigns() {
    return API.get("campaigns", "/campaigns");
  }

  function loadAccounts() {
    return API.post("accounts", "/adminaccounts", {
      body: {
        userId: "us-east-1:cbbc073d-c081-420c-9ee7-eba81d2c6e6d",
      },
    });
  }

  function accountsOverlay() {
    setAccountLimit(true);
  }

  function changeScreen(newScreen) {
    setViewPaymentModal(false);
    setViewAddAccountModal(false);
    setScreen(newScreen);
  }

  function showViewCampaignModal(campaignId) {
    setCampaignId(campaignId);
    setViewCampaignModal(true);
  }

  function renderAccounts() {
    return (
      <div className="Accounts">
        <Row>
          <Col sm={2}>
            {!isLoading ? (
              <div>
                {isAdmin ? (
                  <div className="accountsColumn">
                    <h3 onClick={() => accountsOverlay()}>
                      <span className="circle">
                        <IosApps fontSize="16px" />
                      </span>
                      {account.firstName} {account.lastName}{" "}
                      <IosArrowDown fontSize="16px" />
                    </h3>

                    {showAccountList ? (
                      <div
                        className="accountsOverlay"
                        onMouseLeave={() => setAccountLimit(false)}
                      >
                        {accounts
                          .filter((account) => account.firstName === "none")
                          .map((account) => (
                            <a
                              style={{ textDecoration: "none" }}
                              href={`/accounts/${account.accountId}`}
                              key={account.accountId}
                            >
                              <h3>
                                <span className="circle">
                                  <IosApps fontSize="16px" />
                                </span>
                                Unverified
                              </h3>
                            </a>
                          ))}
                        {accounts
                          .filter((account) => account.firstName !== "none")
                          .map((account) => (
                            <a
                              style={{ textDecoration: "none" }}
                              href={`/accounts/${account.accountId}`}
                              key={account.accountId}
                            >
                              <h3>
                                <span className="circle">
                                  <IosApps fontSize="16px" />
                                </span>
                                {account.firstName} {account.lastName}{" "}
                              </h3>
                            </a>
                          ))}
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <img
                    height="25"
                    alt="conversify logo"
                    src={props.props.adminLogo}
                  />
                )}
              </div>
            ) : null}
            <br />
            {isAdmin ? (
              <div>
                <h4
                  onClick={() => setScreen("home")}
                  className={screen === "home" ? "active" : null}
                  style={{ marginBottom: "0px" }}
                >
                  <IosHome
                    fontSize="16px"
                    color={screen === "home" ? "#FF10A2" : null}
                  />
                  Home
                </h4>
                <br />
              </div>
            ) : null}
            <h4
              onClick={() => setScreen("templates")}
              className={screen === "templates" ? "active" : null}
            >
              <IosPaper
                fontSize="16px"
                color={screen === "templates" ? "#FF10A2" : null}
              />
              Templates
            </h4>
            <h4
              onClick={() => setScreen("campaigns")}
              className={screen === "campaigns" ? "active" : null}
            >
              <IosFunnel
                fontSize="16px"
                color={screen === "campaigns" ? "#FF10A2" : null}
              />
              Campaigns
            </h4>
            <h4
              onClick={() => setScreen("conversations")}
              className={screen === "conversations" ? "active" : null}
            >
              <IosChatboxes
                fontSize="16px"
                color={screen === "conversations" ? "#FF10A2" : null}
              />
              Inbox
            </h4>
            <br />
            {isAdmin ? (
              <h4
                onClick={() => setScreen("settings")}
                className={screen === "settings" ? "active" : null}
                style={{ marginTop: "0px" }}
              >
                <IosSettings
                  fontSize="16px"
                  color={screen === "settings" ? "#FF10A2" : null}
                />
                Settings
              </h4>
            ) : (
              <h4 onClick={() => props.props.handleLogout()}>
                <IosArrowForward fontSize="16px" /> Logout
              </h4>
            )}
          </Col>
          <Col sm={10}>
            <div className="cardComponent">
              {screen === "home" && account ? (
                campaigns.length > 0 ? (
                  <div>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Account</th>
                          <th>Sending Limit</th>
                          <th>Contacts</th>
                          <th>Requests</th>
                          <th>Connections</th>
                          <th>Replies</th>
                          <th>Positive Replies</th>
                        </tr>
                      </thead>
                      <tbody>
                        {campaigns.map((campaign, i) => (
                          <tr key={campaign.campaignId}>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                showViewCampaignModal(campaign.campaignId)
                              }
                            >
                              {campaign.title}
                            </td>
                            <td>{campaign.username}</td>
                            <td>{campaign.dailyLimit} </td>
                            <td>{campaign.contacts}</td>
                            <td>
                              {campaign.requests}{" "}
                              {campaign.contacts > 0 &&
                              campaign.requests > 0 ? (
                                <small className="blue">
                                  {parseInt(
                                    (campaign.requests / campaign.contacts) *
                                      100
                                  )}
                                  %
                                </small>
                              ) : null}
                            </td>
                            <td>
                              {campaign.connections}{" "}
                              {campaign.contacts > 0 &&
                              campaign.connections > 0 ? (
                                <small className="blue">
                                  {parseInt(
                                    (campaign.connections / campaign.contacts) *
                                      100
                                  )}
                                  %
                                </small>
                              ) : null}
                            </td>
                            <td>
                              {campaign.conversations}{" "}
                              {campaign.contacts > 0 &&
                              campaign.conversations ? (
                                <small className="blue">
                                  {parseInt(
                                    (campaign.conversations /
                                      campaign.contacts) *
                                      100
                                  )}
                                  %
                                </small>
                              ) : null}
                            </td>
                            <td>
                              {campaign.positives}{" "}
                              {campaign.contacts > 0 &&
                              campaign.positives > 0 ? (
                                <small className="blue">
                                  {parseInt(
                                    (campaign.positives / campaign.contacts) *
                                      100
                                  )}
                                  %
                                </small>
                              ) : null}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <Row>
                    <Col sm={12}>
                      <div className="home">
                        <h2>
                          Follow these steps to get started with{" "}
                          {account.firstName} {account.lastName}'s account
                        </h2>
                        <div
                          onClick={() => setScreen("templates")}
                          className="step"
                          style={{ borderTop: "1px solid #bdc3c7" }}
                        >
                          <p>
                            <IosArrowForward fontSize="16px" color="#FF10A2" />{" "}
                            Create a templated sequence of LinkedIn messages
                          </p>
                        </div>
                        <div
                          onClick={() => setScreen("contacts")}
                          className="step"
                        >
                          <p>
                            <IosArrowForward fontSize="16px" color="#FF10A2" />{" "}
                            Generate a CSV of contacts from a Sales Nav list
                          </p>
                        </div>
                        <div
                          onClick={() => setScreen("campaigns")}
                          className="step"
                        >
                          <p>
                            <IosArrowForward fontSize="16px" color="#FF10A2" />{" "}
                            Start a campaign using your template and CSV of
                            contacts
                          </p>
                        </div>
                        <div
                          onClick={() => setScreen("conversations")}
                          className="step"
                        >
                          <p>
                            <IosArrowForward fontSize="16px" color="#FF10A2" />{" "}
                            Manage conversations from {account.firstName}{" "}
                            {account.lastName}'s account
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )
              ) : null}
              {screen === "contacts" ? (
                <Contacts props={props} accountId={props.accountId} />
              ) : null}
              {screen === "campaigns" ? (
                <Campaigns
                  changeScreen={changeScreen}
                  props={props}
                  accountId={props.accountId}
                  isAdmin={isAdmin}
                />
              ) : null}
              {screen === "conversations" ? (
                <Conversations
                  campaigns={campaigns}
                  props={props}
                  accountId={props.accountId}
                />
              ) : null}
              {screen === "broadcast" ? (
                <Broadcast
                  campaigns={campaigns}
                  props={props}
                  accountId={props.accountId}
                />
              ) : null}

              {screen === "templates" ? (
                <Templates
                  accountId={props.accountId}
                  setScreen={setScreen}
                  props={props}
                  isAdmin={isAdmin}
                />
              ) : null}

              {screen === "settings" ? (
                <Row>
                  <Col sm={12}>
                    <div className="home">
                      <h2>Settings</h2>
                      <div
                        onClick={() =>
                          props.props.history.push(
                            `/accounts/${props.accountId}/edit`
                          )
                        }
                        className="step"
                        style={{ borderTop: "1px solid #bdc3c7" }}
                      >
                        <p>
                          <IosArrowForward fontSize="16px" color="#FF10A2" />{" "}
                          Update LinkedIn password for {account.firstName}{" "}
                          {account.lastName}'s account
                        </p>
                      </div>
                      <div
                        onClick={handleDelete}
                        className="step"
                      >
                        <p>
                          <IosArrowForward fontSize="16px" color="#FF10A2" />{" "}
                          Delete {account.firstName}{" "}
                          {account.lastName}'s account
                        </p>
                      </div>
                      <div
                        onClick={() => setViewPaymentModal(true)}
                        className="step"
                      >
                        <p>
                          <IosArrowForward fontSize="16px" color="#FF10A2" />{" "}
                          Buy more accounts
                        </p>
                      </div>
                      <div
                        onClick={() => props.props.handleLogout()}
                        className="step"
                      >
                        <p>
                          <IosArrowForward fontSize="16px" color="#FF10A2" />{" "}
                          Logout
                        </p>
                      </div>
                      <br />
                      <div style={{ padding: "20px" }}>
                        <h5>API Key</h5>
                        <p>{account.userId}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : null}
            </div>
          </Col>
        </Row>
        <Modal
          show={viewAddAaccountModal}
          onHide={() => setViewAddAccountModal(false)}
          dialogClassName="modal-90w"
        >
          <Modal.Body>
            <div className="modal-card text-center">
              <NewAccount
                changeScreen={changeScreen}
                history={props.props.history}
              />
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={viewPaymentModal}
          onHide={() => setViewPaymentModal(false)}
          dialogClassName="modal-90w"
        >
          <Modal.Body>
            <div className="modal-card text-center">
              <Settings setScreen={setScreen} props={props} />
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={viewCrmModal}
          onHide={() => setViewCrmModal(false)}
          dialogClassName="modal-90w"
        >
          <Modal.Body>
            <div className="modal-card text-center" style={{ padding: "20px" }}>
              <Row>
                <Col className="text-center">
                  <p style={{ opacity: 1, textAlign: "center" }}>
                    <b>Custom CRM Integration</b>
                  </p>

                  <p>Connect your CRM with all your LinkedIn accounts.</p>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={viewReferFriend}
          onHide={() => setViewReferFriend(false)}
          dialogClassName="modal-90w"
        >
          <Modal.Body>
            <div className="modal-card text-center" style={{ padding: "20px" }}>
              <Row>
                <Col className="text-center">
                  <p style={{ opacity: 1, textAlign: "center" }}>
                    <b>Your Referral Link</b>
                  </p>
                  <p className="referLink">{`https://www.conversify.ai/r/${props.accountId}`}</p>
                  <p>
                    We'll refund you one month's payment for each customer you
                    refer.
                  </p>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={viewCampaignModal}
          onHide={() => setViewCampaignModal(false)}
          dialogClassName="modal-90w"
        >
          <Modal.Body>
            <div className="modal-card text-center">
              <ViewCampaign
                changeScreen={props.changeScreen}
                props={props}
                campaignId={campaignId}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  return <div className="">{renderAccounts()}</div>;
}
