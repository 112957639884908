import "./Accounts.css";

import { API, Auth } from "aws-amplify";
import { Col, Row } from "react-bootstrap";
import React, { useEffect } from "react";

import IosRefresh from "react-ionicons/lib/IosRefresh";

export default function Dashboard(props) {

  useEffect(() => {

    async function onLoad() {
      try {
        const user = await Auth.currentUserInfo();
        const accounts = await loadAccounts();
        console.log(user)
        const accountFromEmail = accounts.filter(
          (account) => ('email' in account) && account.email.toLowerCase() === user.attributes.email.toLowerCase()
        );

        if (accounts.length === 0) {
          props.history.push(`/accounts/new`);
        } else {
          if (accountFromEmail.length > 0) {
            props.history.push(`/accounts/${accountFromEmail[0].accountId}`);
          } else {
            props.history.push(`/accounts/new`);
          }
        }
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.history, props]);

  function loadAccounts() {
    return API.post("accounts", "/adminaccounts", {
      body: {
        userId: "us-east-1:cbbc073d-c081-420c-9ee7-eba81d2c6e6d",
      },
    });
  }

  return (
    <div>
      <br />
      <br />
      <div className="form-wrapper">
          <Row>
            <Col className="text-center">
              <p style={{ opacity: 1, textAlign: "center" }}>
                <b> Welcome! </b>
              </p>

              <p>We are loading your account.</p>
            </Col>
          </Row>
          <br />
          <p className="text-center">
            <IosRefresh fontSize="60px" color="#FF10A2" rotate={true} />
          </p>
        </div>
    </div>
  );
}
