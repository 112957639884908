import "./NewCampaign.css";

import {
  ControlLabel,
  FormControl,
  FormGroup,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API, Auth } from "aws-amplify";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import MdArrowBack from "react-ionicons/lib/MdArrowBack";
import MdArrowForward from "react-ionicons/lib/MdArrowForward";
import ViewCampaign from "./ViewCampaign";

export default function NewCampaign(props) {
  const [title, setTitle] = useState("");
  const [dailyLimit, setDailyLimit] = useState(40);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [question, setQuestion] = useState("title");
  const [campaign, setCampaign] = useState(null);

  useEffect(() => {
    function loadProfile(userId) {
      return API.get("profiles", `/myprofile/${userId}`);
    }

    async function onLoad() {
      try {

        try {
          const user = await Auth.currentUserInfo();
          const profile = await loadProfile(user['id']);
          setProfile(profile);
        } catch (e) {
          console.log(e);
          console.log("cant load profile");
        }
        setIsPageLoading(false);
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.accountId]);

  function validateForm() {
    return title.length > 3;
  }

  function handleTitleValidation() {
    return title.length > 3;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsPageLoading(true);
    try {
      const campaign = await createCampaign({
        userId: "us-east-1:cbbc073d-c081-420c-9ee7-eba81d2c6e6d",
        title,
        dailyLimit,
        accountId: props.accountId,
        profileId: profile.profileId,
      });
      setCampaign(campaign);
      setIsPageLoading(false);
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  function createCampaign(campaign) {
    return API.post("campaigns", "/campaigns", {
      body: campaign,
    });
  }

  return (
    <div className="NewCampaign">
      {isPageLoading ? (
        <IosRefresh fontSize="60px" color="#FF10A2" rotate={true} />
      ) : campaign ? (
        <ViewCampaign
          setScreen={props.setScreen}
          props={props}
          campaignId={campaign.campaignId}
        />
      ) : (
        <div>
          <form onSubmit={handleSubmit}>
            {question === "title" ? (
              <FormGroup controlId="title">
                <ControlLabel>
                  What is the name of your campaign?{" "}
                  <small>(for your reference later)</small>
                </ControlLabel>
                <FormControl
                  value={title}
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                />
                <button
                  className="other-btn pull-right"
                  onClick={() => setQuestion("description")}
                  disabled={!handleTitleValidation()}
                >
                  Next
                  <MdArrowForward fontSize="16px" />
                </button>
                <br className="clearfix" />
              </FormGroup>
            ) : (
              <FormGroup controlId="dailyLimit">
                <ControlLabel>
                  How many connection requests should we send daily?{" "}
                </ControlLabel>
                <FormControl
                  value={dailyLimit}
                  type="number"
                  onChange={(e) => setDailyLimit(e.target.value)}
                />
                <span
                  className="other-btn pull-left"
                  onClick={() => setQuestion("title")}
                >
                  <MdArrowBack fontSize="16px" />
                  Back
                </span>
                <button
                  className="other-btn pull-right"
                  type="submit"
                  isLoading={isLoading}
                  disabled={!validateForm()}
                >
                  Create Campaign
                  <MdArrowForward fontSize="16px" />
                </button>
                <br className="clearfix" />
              </FormGroup>
            )}
          </form>
        </div>
      )}
    </div>
  );
}
