import "./Payment.css";

import { Elements, StripeProvider } from "react-stripe-elements";
import React, { Component } from "react";

import { API } from "aws-amplify";
import BillingForm from "../components/BillingForm";
import MdCheckmark from "react-ionicons/lib/MdCheckmark";
import config from "../config";

export default class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      paymentComplete: false,
    };
  }

  billUser(details) {
    return API.post("profiles", "/billing", {
      body: details,
    });
  }

  handleFormSubmit = async ({ accounts, token, error }) => {
    if (error) {
      alert(error);
      return;
    }

    this.setState({ isLoading: true });

    try {
      await this.billUser({
        source: token.id,
        name:
          this.props.user.attributes.given_name +
          " " +
          this.props.user.attributes.family_name,
        email: this.props.user.attributes.email,
        profileId: this.props.profileId,
        accounts,
      });
      this.setState({ isLoading: false, paymentComplete: true });
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <div className="Payment">
        {this.state.paymentComplete ? (
          <div className="text-center">
            <br />
            <h1>
              <MdCheckmark fontSize="60px" color="#FF10A2" />
            </h1>
            <p>Purchase successful. Thank you!</p>
            <br />
            <br />
          </div>
        ) : (
          <StripeProvider apiKey={config.STRIPE_KEY}>
            <Elements>
              <BillingForm
                loading={this.state.isLoading}
                onSubmit={this.handleFormSubmit}
              />
            </Elements>
          </StripeProvider>
        )}
      </div>
    );
  }
}
